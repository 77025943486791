<template>
    <div class="main">
        <agent-info-comp></agent-info-comp>
        <agent-sub-title>에이전트 리스트</agent-sub-title>
        <div class="data">
            <agent-recursion-comp2 :list="agentList"></agent-recursion-comp2>
        </div>
    </div>
</template>

<script>

    import {Loading} from 'element-ui';
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import AgentSubTitle from "../../components/agent/AgentSubTitle";
    import PartnerSubSelectorComp from "../../components/agent/PartnerSubSelectorComp";
    import {agentMixin} from "../../common/agent/agentMixin";
    import {getAgentList} from "../../network/agent/commonRequest";
    import AgentRecursionComp2 from "../../components/agent/AgentRecursionComp2";
    import AgentInfoComp from "../../components/agent/AgentInfoComp";

    export default {
        name: "AgentSubList",
        components: {
            AgentInfoComp,
            AgentRecursionComp2,
            PartnerSubSelectorComp,
            AgentSubTitle,
            DateSelectorComp,
        },
        mixins: [agentMixin],
        data() {
            return {

                sdata: [],
                months: [
                    {'key': '1월', 'val': '1'}, {'key': '2월', 'val': '2'}, {'key': '3월', 'val': '3'}, {
                        'key': '4월',
                        'val': '4'
                    }, {'key': '5월', 'val': '5'}, {'key': '6월', 'val': '6'},
                    {'key': '7월', 'val': '7'}, {'key': '8월', 'val': '8'}, {'key': '9월', 'val': '9'}, {
                        'key': '10월',
                        'val': '10'
                    }, {'key': '11월', 'val': '11'}, {'key': '12월', 'val': '12'}
                ],
                agent: {},
                pageNum: 1,
                pageSize: 1,
                orderBy: 'id DESC',
                pageTotal: 0,
                agentList: [],

            }
        }
        ,
        methods: {
            getAgentList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getAgentList(this.agent, null, null).then(res => {
                    this.pageTotal = res.data.total;
                    this.agentList = res.data.data;
                    loadingInstance.close();
                    console.log(this.agentList)
                })
            },
            search() {
                this.getAgentList()
            },
            setPartnerId(partnerId) {
                this.agent.id = partnerId;
            },
            setStartDate(date) {
                this.startTime = date
            },
            setEndDate(date) {
                this.endTime = date
            },


        },
        created() {
            this.search();
            this.$bus.$on('agentEdited2', (calbc) => {
                this.getAgentList()
            })

        }
    }
</script>
<style scoped>
    .data {
        width: 100%;
        height: calc(100vh - 185px);
        overflow-y: scroll;
        align-items: flex-start;
    }

</style>